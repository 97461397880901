import React from "react"
import cx from "classnames"
import { Link } from "gatsby"

import Url from "~/models/url"

import Chevron from "~/ui/identity/icon/chevron"

type Props = {
  className?: string
  breadcrumbs: Url[]
}

const Breadcrumbs = ({ className, breadcrumbs }: Props) => {
  return (
    <ul id="breadcrumb" className={cx(className)}>
      {breadcrumbs.map((url: Url, index: number) => (
          <li key={url.id} className="inline-block mr-3">
            <Link
              to={url.pathname}
              className="mr-3 inline-block align-middle text-sm"
            >
              {url.name}
            </Link>
            {breadcrumbs.length !== index + 1 && (
              <span className="inline-block align-middle">
                <Chevron width={6} fill="#2D2926" />
              </span>
            )}
          </li>
      ))}
    </ul>
  )
}

export default Breadcrumbs
