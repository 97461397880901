import React from "react"

import TitledSection from "~/ui/layout/titled-section"
import Accordion from "~/ui/components/generic/accordion"
import Item from "~/ui/components/generic/item"
import sanitize from "~/helpers/sanitize"

type ItemProps = {
  title: string
  body: string
  image?: string
  alt: string
}

type Props = {
  title: string
  description: string
  items: ItemProps[]
}

function CollapsibleList({ title, description, items }: Props) {
  return (
    <TitledSection title={title} description={description}>
      <div>
        <Accordion>
          {items.map((item, index) => (
            <Item key={index} title={item.title}>
              <div className="text-base text-font-2 pb-6">
                <div dangerouslySetInnerHTML={sanitize(item.body)}></div>
                {item.image && (
                  <div className="mt-6 lg:py-4 bg-yellow-200 rounded-lg">
                    <img
                      src={`/images/${item.image}`}
                      alt={item.alt}
                      className="block w-[234px] min-h-[152px] mx-auto"
                      decoding="async"
                    />
                  </div>
                )}
              </div>
            </Item>
          ))}
        </Accordion>
      </div>
    </TitledSection>
  )
}

export default CollapsibleList
