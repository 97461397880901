import React from "react"
import { GatsbyImage } from "gatsby-plugin-image"
import cx from "classnames"

type Props = {
  filename?: string
  gatsbyImageData?: any
  url?: string
  alt: string
  className?: string
  width?: number
  zoom?: boolean
}

function Image({ gatsbyImageData, url, alt, className, width, zoom }: Props) {
  if (url) {
    return <img className={cx(className)} src={url} alt={alt} />
  }

  return (
    <GatsbyImage
      className={cx(
        "overflow-hidden relative",
        {
          "transform duration-300 transition hover:scale-105 hover:opacity-90":
            zoom,
        },
        className
      )}
      image={gatsbyImageData}
      alt={alt}
      style={{
        width: `${width}px`,
      }}
    />
  )
}

export default Image
