import React from "react"
import { Link } from "gatsby"
import cx from "classnames"

type Props = {
  className?: string
  children: string | JSX.Element
  type: "primary" | "secondary"
  to: string
}

function LinkButton(props: Props) {
  const { children, className, type } = props

  return (
    <Link
      to={props.to}
      className={cx(
        "font-medium inline-block py-3 px-9 leading-none text-center text-lg rounded-lg",
        {
          "bg-blue-600 text-white hover:bg-blue-700": type == "primary",
        },
        {
          "border-2 border-blue-600 bg-white text-blue-600 hover:bg-blue-600 hover:text-white":
            type == "secondary",
        },
        className
      )}
    >
      {children}
    </Link>
  )
}

export default LinkButton
