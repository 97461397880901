import React from "react"
import cx from "classnames"
import sanitize from "~/helpers/sanitize"

type PageCoverProps = {
  title: string
  description: string
  className?: string
  color: string
  image: string,
  alt: string,
}

PageCover.defaultProps = {
  color: "yellow",
}

function PageCover({
  className,
  title,
  description,
  image,
  alt,
  color,
}: PageCoverProps) {
  return (
    <section
      className={cx("overflow-hidden", className, {
        "bg-[#5E6EFF]": color == "blue",
        "bg-[#EFE9EF]": color == "purple",
        "bg-[#6BFFB8]": color == "green",
        "bg-yellow-200": color == "yellow",
        "bg-orange-500": color == "orange",
        "bg-yellow-300": color == "yellow-300",
      })}
    >
      <div className="relative justify-between md:flex items-center max-w-screen-xl mx-auto py-7 lg:py-0 px-4 md:px-10 xl:px-20">
        <img
          src={`/images/general/mandala-${color}-2.svg`}
          alt=""
          className="w-[220px] absolute -bottom-[136px] -right-[92px] lg:-top-[15px] lg:-right-[31px]"
          decoding="async"
        />

        <div className="relative z-10 flex-1 lg:py-10">
          <h1 className="mb-2 lg:mb-5 text-font-1 font-bold text-4xl lg:text-6xl tracking-[-0.02em]">
            {title}
          </h1>

          <p
            className={cx("text-base lg:text-lg", {
              "text-white": color == "blue",
              "text-font-1": color != "blue",
            })}
            dangerouslySetInnerHTML={sanitize(description)}
          ></p>
        </div>

        <div className="relative z-10 flex-1 text-right">
          <img
            src={`/images/${image}`}
            alt={alt}
            className="hidden xl:inline-block flex-1 z-10 max-w-[280px]"
            decoding="async"
          />
        </div>

        <img
          src={`/images/general/mandala-${color}-1.svg`}
          alt=""
          className="w-[200px] absolute -bottom-[0px] lg:-bottom-[68px] -left-[60px]"
          decoding="async"
        />
      </div>
    </section>
  )
}

export default PageCover
